import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as UserAction from '@store/actions/users.actions';
import { setMe } from 'lingo2-chat-app';
import { values as _values } from 'lodash';
import { of } from 'rxjs';
import { concatMap, switchMap, map, withLatestFrom, catchError } from 'rxjs/operators';
import {
  AccountService,
  ContentService,
  ProfileService,
  MeetingsService,
  AccountDetailsType,
} from 'src/app/core/services';

import * as ProfileAction from '../actions/profile.actions';
import * as UsersAction from '../actions/users.actions';
import { getMe } from '../reducers/profile.reducer';
import { getUsers } from '../reducers/users.reducer';

@Injectable()
export class UserEffects {
  public setMeAsCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.setMeAsCurrentUser),
      withLatestFrom(this.store.select(getMe)),
      concatMap(([, me]) => of(UsersAction.setCurrentUser({ user: me }))),
    ),
  );

  public addMeAsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMeSuccess),
      concatMap(({ me }) => {
        if (me) {
          return [setMe({ me }), UsersAction.setUser({ user: me })];
        } else {
          return of({ type: '[User Effects] Me is empty, cant add' });
        }
      }),
    ),
  );

  public loadUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadUserById),
      withLatestFrom(this.store.select(getUsers)),
      switchMap(([{ user_id }, userCollection]) => {
        if (userCollection[user_id]) {
          return of({ type: '[User Effects] User already loaded.' });
        }
        return this.accountService.getUserById(user_id).pipe(map((user) => UsersAction.setUser({ user })));
      }),
    ),
  );

  public updateUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.anyUserUpdateActionComplete),
      concatMap(({ user_id }) =>
        this.accountService.getUserById(user_id).pipe(map((user) => UsersAction.setUser({ user }))),
      ),
    ),
  );

  public loadUsersById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadUsersById),
      withLatestFrom(this.store.select(getUsers)),
      switchMap(([{ users_id }, userCollection]) => {
        const existedUsers = users_id.filter((id) => !!userCollection[id]);
        return of(UserAction.loadUsers({ user_ids: users_id }));
      }),
    ),
  );

  public loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadUsers),
      concatMap(({ user_ids }) => {
        const usersDetails: AccountDetailsType[] = [
          'id',
          'first_name',
          'last_name',
          'country',
          'userpic_id',
          'userpic',
          'slug',
        ];
        return this.accountService
          .findAccounts({ id: user_ids }, { page: 1, pageSize: user_ids.length }, usersDetails)
          .pipe(map((users) => UsersAction.loadUsersSuccess({ users: users.results })));
      }),
    ),
  );

  public loadCurrentUserBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadCurrentUserBySlug),
      map(({ slug }) => slug),
      withLatestFrom(this.store.select(getUsers)),
      concatMap(([slug, users]) => {
        const slugUser = _values(users).find((u) => u.slug === slug);
        if (slugUser) {
          return of(UsersAction.setCurrentUser({ user: slugUser }));
        } else {
          return this.accountService.getUserBySlug(slug).pipe(map((user) => UsersAction.setCurrentUser({ user })));
        }
      }),
    ),
  );

  public updateUserStatsWhenSetCurrentProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.setCurrentUser),
      concatMap(({ user }) => {
        if (!user) {
          return [];
        }
        return of(UsersAction.updateUserStats({ user_id: user.id }));
      }),
    ),
  );

  // TODO: Save profiles to use it more than 1 time
  public loadCurrentProfileWhenCurrentUserIsSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.setCurrentUser),
      concatMap(({ user }) => {
        if (!user) {
          return [];
        }
        return this.profileService
          .getProfile(user.id)
          .pipe(map((profile) => UsersAction.setCurrentProfile({ profile })));
      }),
    ),
  );

  public subscribeToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.subscribeToUser),
      switchMap(({ user_id }) =>
        this.accountService.subscribe(user_id).pipe(map(() => UsersAction.anyUserUpdateActionComplete({ user_id }))),
      ),
    ),
  );

  public unsubscribeFromUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.unsubscribeFromUser),
      switchMap(({ user_id }) => {
        if (!user_id) {
          return [];
        }
        return this.accountService
          .unsubscribe(user_id)
          .pipe(map(() => UsersAction.anyUserUpdateActionComplete({ user_id })));
      }),
    ),
  );

  public updateUserStatsWhenSetUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.setUser),
      concatMap(({ user, statsUpdate }) => {
        if (statsUpdate) {
          return of(UsersAction.updateUserStats({ user_id: user.id }));
        }
        return of({ type: '[Users Effects] User is set, but update stats dont need.' });
      }),
    ),
  );

  public updateUserStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.updateUserStats),
      withLatestFrom(this.store.select(getUsers)),
      concatMap(([{ user_id, force }, users]) => {
        const actions = [];
        if (!users[user_id]) {
          return of({ type: '[Users Effects] User is empty' });
        }
        const stats = users[user_id].stats;
        if (force || stats.subscribers_count === undefined) {
          actions.push(UsersAction.loadUserById({ user_id }));
        }
        if (force || stats.publications_count === undefined) {
          actions.push(UsersAction.updateContentStats({ user_id }));
        }
        if (force || stats.meetings_count === undefined) {
          actions.push(UsersAction.updateMeetingStats({ user_id }));
        }
        if (force || stats.lessons_points === undefined) {
          actions.push(UsersAction.updateGameStats({ user_id }));
        }
        return actions;
      }),
    ),
  );

  public updateUserContentStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.updateContentStats),
      concatMap(({ user_id }) =>
        this.contentService
          .getStats(user_id)
          .pipe(map((stats) => UsersAction.updateContentStatsComplete({ user_id, stats }))),
      ),
    ),
  );

  public updateUserMeetingStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.updateMeetingStats),
      concatMap(({ user_id }) =>
        this.meetingService.getStats(user_id).pipe(
          map((stats) => UsersAction.updateMeetingStatsComplete({ user_id, stats })),
          catchError(() => of({ type: '[Meeting Service] Get stats error' })),
        ),
      ),
    ),
  );

  public updateUserGameStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.updateGameStats),
      concatMap(({ user_id }) =>
        this.contentService.getGameStats(user_id).pipe(
          map((stats) => UsersAction.updateGameStatsComplete({ user_id, stats })),
          catchError(() => of({ type: '[Content Service] Get stats error' })),
        ),
      ),
    ),
  );

  public loadTeachers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadTeachers),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([, me]) => {
        if (!me || me.is_guest || me.is_guest === undefined) {
          return of({ type: '[Users Effects] Loading my teachers as guest is not allowed' });
        }
        return this.accountService
          .findMyTeachers({}, { page: 1, pageSize: 50, total: 50, totalPages: 1 })
          .pipe(map(({ results }) => UsersAction.loadUsersSuccess({ users: results })));
      }),
    ),
  );

  public loadMySubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersAction.loadMySubscriptions),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([, me]) => {
        if (!me || me.is_guest || me.is_guest === undefined) {
          return of({ type: '[Users Effects] Loading subscription as guest is not allowed' });
        }
        return this.accountService
          .getSubscriptions({}, { page: 1, pageSize: 50, total: 50, totalPages: 1 })
          .pipe(map(({ results }) => UsersAction.loadUsersSuccess({ users: results })));
      }),
    ),
  );

  public constructor(
    private actions$: Actions,
    private meetingService: MeetingsService,
    private accountService: AccountService,
    private contentService: ContentService,
    private profileService: ProfileService,
    private store: Store,
  ) {}
}
